
  const Cookie = process.client ? require('js-cookie') : undefined;
  export default {
    name: 'PageGenerator',
    // serverCacheKey() {
    //   return `PageGeneratorComponent_${Math.floor(Date.now() / 100000)}`;
    // },
    data() {
      const links = this.pageData.tags.link || [];
      const scripts = this.pageData.tags.script || [];
      return {
        scripts,
        links,
        isDestroyed: false,
        deferScripts: [],
        deferScriptsElements: [],
      };
    },
    props: {
      pageData: {
        type: Object,
        required: true,
      },
    },
    head() {
      if (!this.pageData.tags.script) this.pageData.tags.script = [];
      const sourceKey = ['link', 'script'];
      if (this.$store.getters['IS_BOT_SERVER_REQUEST']) {
        Object.keys(this.pageData.tags).forEach((key) => {
          if (key === 'script') this.pageData.tags[key] = [];
          for (let i = 0; i < this.pageData.tags[key].length; i++) {
            if (this.pageData.tags[key].href?.match(/fa\./gi) || this.pageData.tags[key].href?.match(/fonts\./gi)) {
              this.pageData.tags.splice(key, i--);
            }
          }
        });
      } else {
        this.pageData.tags.script.push({
          //
          src: 'https://mpower-pages-prod.s3.amazonaws.com/nuxt.script.js',
          body: true,
          type: 'text/javascript',
          extension: 'js',
        });
      }
      sourceKey.forEach((key) => {
        if (Array.isArray(this.pageData.tags[key])) {
          this.pageData.tags[key].forEach((el) => {
            //rebuild
            el.deffer = true;
            if (key === sourceKey[1] || el.as === sourceKey[1] || el.preload === true) {
              el.body = true;
            }
          });
        }
      });

      return this.pageData.tags;
    },
    mounted() {
      this.deferScripts = [...(this.pageData.tags.deferScript || [])];
      this.addDeferScript();
      if (this.$route.query.uid) {
        Cookie.set('account_manager', this.$route.query.uid);
      }
    },
    destroyed() {
      this.isDestroyed = true;
      this.deferScriptsElements.forEach((el) => el.parentNode.removeChild(el));
    },
    methods: {
      addDeferScript() {
        const script = this.deferScripts.shift();
        if (script) {
          const el = document.createElement('script');
          el.src = script.src;
          el.onload = () => !this.isDestroyed && this.addDeferScript();
          el.onerror = () => !this.isDestroyed && this.addDeferScript();
          document.body.appendChild(el);

          this.deferScriptsElements.push(el);
        }
      },
    },
  };
